import { NavContext, Title } from '@bpm-web-app/components';
import { ActionModal } from '@bpm-web-app/components/src/lib/shared/action-modal/action-modal';
import { ArtistApplicationStatus } from '@bpm-web-app/download-api-sdk';
import { useArtistProfileStatus } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/dot-notation
const SUPREME_URL = process.env['NEXT_PUBLIC_SUPREME_PLATFORM_URL'];

export default function Index({ title }: { title: string }) {
    const router = useRouter();
    const { application_status: applicationStatus } = useArtistProfileStatus();
    const { hideNav } = useContext(NavContext);

    useEffect(() => {
        if (applicationStatus === ArtistApplicationStatus.Approved) {
            router.replace('/dashboard');
        } else if (applicationStatus === ArtistApplicationStatus.Denied) {
            router.replace('/artist-application');
        } else if (applicationStatus === ArtistApplicationStatus.NotSubmitted) {
            router.replace('/artist-application');
        } else {
            hideNav();
        }
    }, [router, applicationStatus, hideNav]);

    return (
        <>
            <ActionModal
                headerTitle="Application Pending"
                title="Application Pending"
                subtitle="Your application is currently pending. Please keep an eye out for an email from us or check back later for updates. We appreciate your patience as we review your submission."
                confirmButtonText="Back to Supreme"
                hideCancel
                onConfirm={() => {
                    router.replace(SUPREME_URL);
                }}
                onClose={() => {
                    router.replace(SUPREME_URL);
                }}
                variant="light"
                isOpen={applicationStatus === ArtistApplicationStatus.Pending}
            />
            <Title title={title} platform="artist" />
        </>
    );
}

export async function getStaticProps() {
    return {
        props: {
            title: 'Home'
        }
    };
}
